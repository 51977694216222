import { createSlice } from '@reduxjs/toolkit';
import {
	getSystemTemplatesAsync,
	getStoreTemplatesAsync,
	addTemplateAsync,
	editTemplateAsync,
	activateTemplateAsync,
	deleteTemplateAsync,
} from './TemplateReducers';
import { Template } from '../../types';
import { product_ids } from '../../types';
import { swal } from '../../utils';

type TemplatesState = {
	suggestedTemplates: Template[];
	storeTemplates: Template[];
	activeTemplate: {
		id?: number;
		name?: string;
		main_title?: string;
		main_text?: string;
		thumbnail?: string;
		type?: 'suggested' | 'store';
		products_ids?: product_ids | [];
		is_Active?: boolean;
	};
	status: 'idle' | 'loading' | 'failed';
};

const initialState: TemplatesState = {
	suggestedTemplates: [],
	storeTemplates: [],
	activeTemplate: {},
	status: 'idle',
};

export const templatesSlice = createSlice({
	name: 'templates',
	initialState,
	reducers: {
		setActiveTemplate: (state, action) => {
			state.activeTemplate = action.payload;
		},
		setSelectedProducts: (state, action) => {
			state.activeTemplate.products_ids = action.payload;
		},
		deleteSelectedProduct: (state, action) => {
			const index = state.activeTemplate.products_ids?.findIndex((product_id) => product_id === action.payload);
			if (index !== undefined && index !== -1) {
				state.activeTemplate.products_ids?.splice(index, 1);
			}
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getSystemTemplatesAsync.pending, (state) => {
				state.status = 'loading';
			})
			.addCase(getSystemTemplatesAsync.fulfilled, (state, action) => {
				state.status = 'idle';
				state.suggestedTemplates = action.payload;
			})
			.addCase(getSystemTemplatesAsync.rejected, (state) => {
				state.status = 'idle';
				state.suggestedTemplates = [];
			})
			.addCase(getStoreTemplatesAsync.pending, (state) => {
				state.status = 'loading';
			})
			.addCase(getStoreTemplatesAsync.fulfilled, (state, action) => {
				state.status = 'idle';
				state.storeTemplates = action.payload;
			})
			.addCase(getStoreTemplatesAsync.rejected, (state) => {
				state.status = 'idle';
				state.storeTemplates = [];
			})
			.addCase(addTemplateAsync.pending, (state) => {
				state.status = 'loading';
			})
			.addCase(addTemplateAsync.fulfilled, (state, action) => {
				state.status = 'idle';
				state.storeTemplates = [...state.storeTemplates, action.payload];
				state.activeTemplate = action.payload;
				swal('إضافة القالب', 'تم إضافة القالب بنجاح إلى النماذج الخاصة بك');
			})
			.addCase(addTemplateAsync.rejected, (state, action) => {
				state.status = 'idle';
				swal('إضافة القالب', action.error.message);
			})
			.addCase(editTemplateAsync.pending, (state) => {
				state.status = 'loading';
			})
			.addCase(editTemplateAsync.fulfilled, (state, action) => {
				state.status = 'idle';
				const index = state.storeTemplates.findIndex((template) => template.id === action.payload.id);
				if (index !== -1) {
					state.storeTemplates[index] = action.payload;
					state.activeTemplate = action.payload;
				}
				swal('إضافة القالب', 'تم تعديل القالب بنجاح');
			})
			.addCase(editTemplateAsync.rejected, (state, action) => {
				state.status = 'idle';
				swal('إضافة القالب', action.error.message);
			})
			.addCase(activateTemplateAsync.pending, (state) => {
				state.status = 'loading';
			})
			.addCase(activateTemplateAsync.fulfilled, (state, action) => {
				state.status = 'idle';
				const index = state.storeTemplates.findIndex((template) => template.id === action.payload.id);
				if (index !== -1) {
					state.storeTemplates[index] = action.payload;
					state.activeTemplate = action.payload;
				}
			})
			.addCase(activateTemplateAsync.rejected, (state, action) => {
				state.status = 'idle';
				swal('إضافة القالب', action.error.message);
			})
			.addCase(deleteTemplateAsync.pending, (state) => {
				state.status = 'loading';
			})
			.addCase(deleteTemplateAsync.fulfilled, (state, action) => {
				state.status = 'idle';
				const index = state.storeTemplates.findIndex((template) => template.id === action.payload.id);
				if (index !== -1) {
					state.storeTemplates.splice(index, 1);
				}
			})
			.addCase(deleteTemplateAsync.rejected, (state, action) => {
				state.status = 'idle';
				swal('إضافة القالب', action.error.message);
			});
	},
});

export const { setActiveTemplate, setSelectedProducts, deleteSelectedProduct } = templatesSlice.actions;

// selectors

export default templatesSlice.reducer;
