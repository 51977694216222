import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../api';
import { TEMPLATES, STORE_TEMPLATES } from '../../endpoints';
import { Template } from '../../types';
import { swal, swalConfirm } from '../../utils';

export const getSystemTemplatesAsync = createAsyncThunk('get suggested templates', async () => {
	const { data } = await axios.get(TEMPLATES);
	return data;
});

export const getStoreTemplatesAsync = createAsyncThunk('get store templates', async () => {
	const { data } = await axios.get(STORE_TEMPLATES);
	return data;
});

export const addTemplateAsync = createAsyncThunk('add template', async ({ template }: { template: Template }) => {
	const { data } = await axios.post(STORE_TEMPLATES, {
		products_ids: template.products_ids,
		template_id : template.id
	});
	return data;
});

export const editTemplateAsync = createAsyncThunk('edit template', async ({ template }: { template: Template }) => {
	const { data } = await axios.put(`${STORE_TEMPLATES}/${template.id}`, {
		products_ids: template.products_ids,
	});
	return data;
});

export const activateTemplateAsync = createAsyncThunk(
	'activate template',
	async ({ template }: { template: Template }) => {
		const { data } = await axios.put(`${STORE_TEMPLATES}/${template.id}/activation`);
		return data;
	}
);

export const deleteTemplateAsync = createAsyncThunk('delete template', async ({ template }: { template: Template }) => {
	swalConfirm
		.fire({
			title: 'تأكيد حذف القالب',
			text: 'في حال تأكيد الحذف لن تستطيع استرجاع إعدادات التخصيص المدرجة على القالب',
			cancelButtonText: 'إلغاء',
			confirmButtonText: 'حذف',
		})
		.then(async (result) => {
			if (result.isConfirmed) {
				const { data } = await axios.delete(`${STORE_TEMPLATES}/${template.id}`);
				data.success && swal('حذف القالب', 'تم حذف القالب بنجاح');
				return data;
			}
		});
	return template;
});
