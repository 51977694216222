import { Menu, Transition } from '@headlessui/react';
import * as React from 'react';
import { classNames } from '../../../utils';

type Props = {
	options: {
		text: string;
		icon: string;
		onClick: () => void;
	}[];
};

const UIDropdown = ({ options }: Props) => {
	return (
		<Menu as="div" className="relative inline-block text-left">
			<div>
				<Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm  hover:bg-gray-50">
					<span className="-mr-1 h-5 w-5 text-gray-400 sicon-ellipsis-filled" aria-hidden="true"></span>
				</Menu.Button>
			</div>

			<Transition
				as={React.Fragment}
				enter="transition ease-out duration-100"
				enterFrom="transform opacity-0 scale-95"
				enterTo="transform opacity-100 scale-100"
				leave="transition ease-in duration-75"
				leaveFrom="transform opacity-100 scale-100"
				leaveTo="transform opacity-0 scale-95"
			>
				<Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
					<div className="">
						{options.map((option) => (
							<Menu.Item key={option.text}>
								{({ active }) => (
									<span
										onClick={option.onClick}
										className={classNames(
											active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
											'group flex items-center px-4 py-2 text-sm'
										)}
									>
										<span
											className={`${option.icon} mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500`}
											aria-hidden="true"
										/>
										<span className="text-lg">{option.text}</span>
									</span>
								)}
							</Menu.Item>
						))}
					</div>
				</Menu.Items>
			</Transition>
		</Menu>
	);
};

export default UIDropdown;
