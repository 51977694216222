import * as React from 'react';
import Card from './Card';
import UIModal from '../Modal/UIModal';
import { useSelector } from 'react-redux';
import { addTemplateAsync, editTemplateAsync } from '../../../features/templates/TemplateReducers';
import { setActiveTemplate, setSelectedProducts } from '../../../features/templates/TemplatesSlice';
import ProductsForm from '../../Products/ProductsForm';
import { type RootState, useAppDispatch } from '../../../app/store';
import { type Template } from '../../../types/templates';
import UILoader from '../UILoader';

interface Props {
	templates: Template[];
	children?: React.ReactNode;
}

const CardsWrapper = ({ templates }: Props) => {
	const [showModal, setShowModal] = React.useState(false);
	const [renderedTemplates, setRenderedTemplates] = React.useState(templates.slice(0, 7));
	const [showAll, setShowAll] = React.useState(false);
	const status = useSelector((state: RootState) => state.templates.status);
	React.useEffect(() => {
		setShowAll(templates.length < 7 ? true : false);
		setRenderedTemplates(templates.slice(0, 7));
	}, [templates]);

	const template: Template = {
		id: -1,
		name: 'عرض الكل',
		type: 'store',
		products_ids: [],
		thumbnail: '',
		is_Active: false,
	};

	const dispatch = useAppDispatch();
	const activeTemplate = useSelector((state: RootState) => state.templates.activeTemplate) as Template;

	const showAllCards = () => {
		setRenderedTemplates(templates);
		setShowAll(true);
	};

	const showEditModal = (template: Template) => {
		dispatch(setActiveTemplate(template));
		setShowModal(true);
	};

	const updateTemplate = () => {
		if (activeTemplate.type === 'suggested') {
			dispatch(addTemplateAsync({ template: activeTemplate }));
		} else {
			dispatch(editTemplateAsync({ template: activeTemplate }));
		}

		setShowModal(false);
	};

	const cancelUpdate = () => {
		dispatch(setActiveTemplate(null));
		dispatch(setSelectedProducts(null));
	};

	return (
		<div className="flex flex-wrap gap-6 justify-start items-center p-5 relative ">
			{renderedTemplates.length === 0 && status == 'idle' && <h1 className='mx-auto text-dark-100 text-lg'>لا يوجد نماذح</h1>  }
			{status === 'loading' && (
				<div className="h-32">
					<UILoader />
				</div>
			)}

			{renderedTemplates.map((template) => (
				<Card key={template.id} template={template} handleClick={showEditModal} />
			))}
			{!showAll && <Card overlay template={template} handleClick={showAllCards} />}
			<UIModal
				saveModal={updateTemplate}
				closeModal={cancelUpdate}
				showModal={showModal}
				setShowModal={setShowModal}
			>
				<ProductsForm />
			</UIModal>
		</div>
	);
};

export default CardsWrapper;
