import * as React from 'react';
import Switch from 'react-switch';

type Props = {
	checked: boolean;
	onChange: (checked: boolean) => void;
};

const UISwitch = ({ checked, onChange }: Props) => {
	return (
		<Switch
			onChange={onChange}
			checked={checked}
			uncheckedIcon={false}
			checkedIcon={false}
			onColor="#76E8CD"
			offColor="#eee"
			onHandleColor="#fff"
			offHandleColor="#fff"
			boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
			activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
			height={18}
			width={30}
			handleDiameter={16}
		/>
	);
};

export default UISwitch;
