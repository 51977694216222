import Swal from 'sweetalert2';
import { SweetAlertOptions } from './types';

export function classNames(...classes: string[]) {
	return classes.filter(Boolean).join(' ');
}

export const swalConfirm = Swal.mixin({
	customClass: {
		confirmButton: 'bg-red-200 text-red-500',
		cancelButton: 'bg-gray-300 text-gray-500',
	},
	icon: 'warning',
	showCancelButton: true,
});

export const swal = (title = '', text = '', icon = 'success') => {
	Swal.fire({
		title,
		text,
		icon,
		confirmButtonText: 'موافق',
		confirmButtonColor: '#76E8CD',
		confirmButtonBorderColor: '#76E8CD',
		timer: 1000,
	} as SweetAlertOptions);
};
