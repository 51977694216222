import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL;
// const DEVELOPMENT = process.env.NODE_ENV === 'development';

const axiosInstance = axios.create({
	baseURL: BASE_URL,
	headers: {
		'Content-Type': 'application/json',
		'Accept': 'application/json',
	},
});

export default axiosInstance;
