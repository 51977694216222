import * as React from 'react';
import UISwitch from '../UISwitch';
import UIDropdown from '../Dropdown/UIDropdown';
import { Template } from '../../../types';
import { useAppDispatch } from '../../../app/store';
import { activateTemplateAsync, deleteTemplateAsync } from '../../../features/templates/TemplateReducers';

type Props = {
	template: Template;
	handleClick: (template: Template) => void;
	overlay?: boolean;
};

function Card({ template, handleClick, overlay }: Props) {
	const { name, main_text, thumbnail, type } = template;
	const [checked, setChecked] = React.useState<boolean>(false);
	const dispatch = useAppDispatch();
	const activateTemplate = (status: boolean | ((prevState: boolean | number) => boolean)) => {
		setChecked(status);
		dispatch(activateTemplateAsync({ template }));
	};

	const deleteTemplate = () => {
		dispatch(deleteTemplateAsync({ template }));
	};

	const dropdownOptions = [
		{ text: 'تعديل', icon: 'sicon-edit', onClick: () => handleClick(template) },
		{ text: 'حذف', icon: 'sicon-trash', onClick: () => deleteTemplate() },
	];

	React.useEffect(() => {
		setChecked(template.is_Active);
	}, [template.is_Active]);

	return (
		<>
			<div
				className="card relative w-[290px] rounded-md border border-gray-200"
				onClick={() => {
					template.type === 'suggested' && handleClick(template);
				}}
			>
				{overlay && (
					<div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center">
						<button
							className="bg-secondary-50 text-primary-l font-medium py-2 px-4 rounded-2xl flex gap-2 justify-center items-center"
							onClick={() => {
								template.id == -1 && handleClick(template);
							}}
						>
							<span className="sicon-eye"></span>
							عرض كل النماذج
						</button>
					</div>
				)}
				<img src={thumbnail} alt="template" className="card__image__img h-[333px] w-full" />
				<div className="card__body absolute z-10 bottom-0 left-0 w-full bg-white">
					{type === 'suggested' && !overlay && (
						<div className="p-4">
							<h4 className="card__body__title text-dark-300 text-md mb-1 font-medium">{name}</h4>
							<p className="card__body__description text-dark-200 text-base font-light truncate">
								{main_text}
							</p>
						</div>
					)}

					{type === 'store' && !overlay && (
						<>
							<div className="card__body__action flex justify-between items-center w-full border-t border-gray-200 p-4 h-20">
								<div className="w-8 h-8 flex items-center justify-center">
									<UIDropdown options={dropdownOptions} />
								</div>
								<UISwitch onChange={activateTemplate} checked={checked} />
							</div>
						</>
					)}
				</div>
			</div>
		</>
	);
}

export default Card;
