import { Oval } from 'react-loader-spinner';
import React from 'react';

const UILoader = () => {
	return (
		<div className="absolute inset-0 h-full w-full bg-transparent z-50 grid place-items-center">
			<Oval
				height={30}
				width={30}
				color="#76E8CD"
				wrapperStyle={{}}
				wrapperClass=""
				visible={true}
				ariaLabel="oval-loading"
				secondaryColor="#76E8CD"
				strokeWidth={4}
				strokeWidthSecondary={4}
			/>
		</div>
	);
};

export default UILoader;
