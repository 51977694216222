import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from '../../api';
import { PRODUCTS_URL } from '../../endpoints';

const initialState = {
	products: [],
	status: 'idle',
};

export const getProductsAsync = createAsyncThunk('/api/products', async () => {
	// TODO: use store_id
	const { data } = await axios.get(`${PRODUCTS_URL}/1/products`);
	return data;
});

export const productsSlice = createSlice({
	name: 'templates',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getProductsAsync.pending, (state) => {
				state.status = 'loading';
			})
			.addCase(getProductsAsync.fulfilled, (state, action) => {
				state.status = 'idle';
				state.products = action.payload;
			});
	},
});

// export const {} = productsSlice.actions;

// selectors

export default productsSlice.reducer;
