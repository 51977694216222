import UIMultiSelect, { SelectOption } from '../UI/MultiSelect/UIMultiSelect';
import { useSelector } from 'react-redux';
import { deleteSelectedProduct, setSelectedProducts } from '../../features/templates/TemplatesSlice';
import * as React from 'react';
import { RootState, useAppDispatch } from '../../app/store';
import { Product, product_id, product_ids } from '../../types';

const ProductsForm = () => {
	const [selectedOptions, setSelectedOptions] = React.useState<SelectOption[]>([]);
	const dispatch = useAppDispatch();
	const selectedProducts: product_ids = useSelector(
		(state: RootState) => state.templates.activeTemplate.products_ids
	);
	const products = useSelector((state: { products: { products: Product[] } }) => state.products.products);

	React.useEffect(() => {
		if (selectedProducts && selectedProducts?.length > 0) {
			setSelectedOptions(
				selectedProducts.map((productId: product_id) => {
					const product = products.find((p: Product) => p.id === productId);
					if (product) {
						return {
							value: product.id,
							label: product.name,
							image: product.image,
						};
					} else {
						return {
							value: 0,
							label: 'product not found',
						};
					}
				})
			);
		} else {
			setSelectedOptions([]);
		}
	}, [selectedProducts, products]);

	const updateTemplateProducts = (selectedOptions: readonly SelectOption[]) => {
		const selectedProductIds = selectedOptions.map((option) => option.value);
		dispatch(setSelectedProducts(selectedProductIds));
	};

	const optionsForSelect: SelectOption[] = products.map((product: Product) => {
		return {
			value: product.id,
			label: product.name,
			image: product.image,
		};
	});

	const deleteSelected = (productId: product_id) => {
		dispatch(deleteSelectedProduct(productId));
	};

	return (
		<div className="flex flex-col justify-center items-start p-5 ">
			<h1 className="text-sm font-medium text-dark-300 mb-3">تخصيص المنتجات المشمولة </h1>
			<div className="flex flex-col justify-center items-start w-full">
				<div className="flex flex-col justify-center items-start w-full">
					<label htmlFor="products" className="text-dark-100 text-sm font-medium mb-3">
						تحديد المنتجات التي يشملها القالب.
					</label>
					<UIMultiSelect
						options={optionsForSelect}
						value={selectedOptions}
						onChange={updateTemplateProducts}
					/>
				</div>
			</div>

			<div className="flex flex-col space-y-4 w-full mt-5 px-4">
				{selectedProducts &&
					selectedProducts.length > 0 &&
					selectedProducts.map((productId: product_id) => {
						const product = products.find((p: Product) => p.id === productId);
						if (product) {
							return (
								<div key={product.id} className="flex justify-between items-center">
									<div className="flex justify-center items-center gap-2">
										<img src={product.image} alt="" className="w-8 h-8 rounded" />
										<span>{product.name}</span>
									</div>
									<span
										className="sicon-trash-2 p-1 text-red-600 rounded-full border border-red-600"
										onClick={() => deleteSelected(product.id)}
									></span>
								</div>
							);
						}
					})}
			</div>
		</div>
	);
};

export default ProductsForm;
