import * as React from 'react';
import { useParams } from 'react-router-dom';
import './style.scss';
import templateData from './data.json';

const TemplatesPreview = () => {
	const { id } = useParams<{ id?: string }>();
	const data = React.useMemo(() => {
		switch (id) {
		case '1':
			return templateData.template_one;
		case '2':
			return templateData.template_two;
		case '3':
			return templateData.template_three;
		default:
			return templateData.template_one;
		}
	}, [id]);

	return (
		<div className={`template-${id}`}>
			<div className="template" style={{ backgroundImage: `${data.background}` }}>
				<div className="template__wrapper">
					{/* Header */}
					<div className="template__header">
						<img className="template__header__logo" src={data.header.logo_image} alt="logo" />
						<img className="template__header__image" src={data.header.header_image} alt="header" />
					</div>
					{/* end of header */}

					{/* Content */}
					<div className="template__content">
						<div className="template__content__heading">
							<span className="title">{data.body.title}</span>
							<span className="subtitle">{data.body.subtitle}</span>
						</div>
						<div className="template__content__main">
							<div className="template__content__heading">
								<span className="subtitle">تشهد الجمعية بتبرع</span>
								<span className="customer">{data.body.customer}</span>
							</div>
							<div className="template__content__heading">
								<span className="subtitle">مبلغ التبرع</span>
								<span className="amount">{data.body.amount}</span>
							</div>
						</div>
					</div>
					{/* end of content */}

					{/* footer */}
					<div className="template__footer">
						{data.footer.image && (
							<img className="" src={data.footer.image} alt="Foooter" />
						)}
						<h3 className="title">{data.footer.title}</h3>
						<p className="subtitle">
							{data.footer.subtitle}
						</p>
					</div>
					{/* end of footer */}
				</div>
			</div>
		</div>
	);
};

export default TemplatesPreview;
