import React, { useEffect } from 'react';
import Panel from '../components/UI/Panel/Panel';
import CardsWrapper from '../components/UI/Card/CardsWrapper';
import { Provider, useSelector } from 'react-redux';
import { RootState, store } from '../app/store';
import PagesHeader from '../components/PagesHeader';

function HomeView() {
	const { suggestedTemplates, storeTemplates } = useSelector((state: RootState) => state.templates);
	useEffect(() => {
		window.addEventListener(
			'message',
			function (event) {
				console.log(event, 'event');
				const origin = event.origin;
				if (origin !== process.env.REACT_APP_DASHBOARD_URL) return;
				if (typeof event.data == 'object' && event.data.call == 'sendValue') {
					console.log(event.data, 'event data');
					if (event.data.darkMode) {
						document.documentElement.classList.add('dark');
					} else {
						document.documentElement.classList.remove('dark');
					}
				}
			},
			false
		);

	}, []);
	return (
		<Provider store={store}>
			<div className="py-8 px-8 mb-8">
				<PagesHeader />
				<Panel title="قوالب الشهاادت المقترحة لك">
					<CardsWrapper templates={suggestedTemplates} />
				</Panel>

				<Panel title="النماذج الخاصة بك">
					<CardsWrapper templates={storeTemplates} />
				</Panel>
			</div>
		</Provider>
	);
}

export default HomeView;
