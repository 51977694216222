import './UIMultiSelect.scss';
import Select, { ActionMeta, components } from 'react-select';
import * as React from 'react';

export type SelectOption = {
	value: number;
	label: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const MenuList = ({ ...props }: any) => {
	return (
		<div className="max-h-44 sm:max-h-72 overflow-y-scroll">
			<components.MenuList {...props}>{props.children}</components.MenuList>
		</div>
	);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Option = ({ ...props }: any) => {

	return (
		<div className="">
			<components.Option {...props}>
				<div className="flex justify-start items-center gap-2">
					<img src={props.data.image} alt="" className="w-8 h-8 rounded" />
					<span>{props.data.label}</span>
				</div>
			</components.Option>
		</div>
	);
};
const selectPlaceholder = (
	<div className="text-sm flex justify-start items-center gap-2 text-dark-100">
		<span className="sicon-search"></span>
		<span>ابحث عن المنتج</span>
	</div>
);

type Props = {
	options: SelectOption[];
	value: SelectOption[];
	onChange: (option: readonly SelectOption[], actionMeta: ActionMeta<SelectOption>) => void;
};
const UIMultiSelect = ({ options, value, onChange }: Props) => {
	return (
		<Select
			value={value}
			isMulti
			name="products"
			onChange={onChange}
			options={options}
			className="react-select-container relative w-full text-right"
			classNamePrefix="react-select"
			components={{
				MenuList,
				Option,
			}}
			placeholder={selectPlaceholder}
			controlShouldRenderValue={false}
			noOptionsMessage={() => 'لا يوجد منتجات'}
			isRtl={true}
			// menuIsOpen={true}
			theme={(theme) => ({
				...theme,
				borderRadius: 0,
				colors: {
					...theme.colors,
					primary25: '#BAF3E6',
					primary: '#CFF7EE',
				},
			})}
		/>
	);
};

export default UIMultiSelect;
